import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ReactHTMLParser from "react-html-parser";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import ButtonGrey from "./ButtonGrey";

const Div = styled.div`
  margin-top: 4em;
  display: flex;
  flex-direction: column;

  .red-circle,
  .gray-circle,
  .black-circle {
    position: relative;
    align-self: center;
    justify-content: center;
  }
  .red-circle {
    background-color: ${colors.primary};
    background-color: #ffffff;
    justify-content: center;
    border: 12px solid ${colors.primary};
  }
  .gray-circle {
    background-color: ${colors.secondaryGray};
    color: ${colors.black};
  }
  .black-circle {
    background-color: ${colors.black};
    color: ${colors.white};
    h2 {
      color: ${colors.white};
    }
  }
  .margin-bottom {
    margin-bottom: 2em;
  }
  .last {
    margin-top: -100px;
  }

  @media (min-width: 500px) {
    .red-circle {
      align-self: flex-end;
      width: 600px;
      height: 600px;
      border: 8px solid ${colors.primary};
      right: -50px;
    }
    .gray-circle {
      align-self: flex-start;
      left: -40px;
    }
    .black-circle {
      align-self: center;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    .gray-circle {
      margin-top: -18%;
    }
    .red-circle {
      width: 800px;
      height: 800px;
    }
  }
  @media (min-width: 1500px) {
    .red-circle {
      right: 0;
    }
  }
  @media (min-width: ${breakpoints.laptopLG}) {
    .red-circle {
      width: 900px;
      height: 900px;
    }
  }
`;

const StyledBlock = styled.div`
  border-radius: 50%;
  width: 130vw;
  height: 130vw;
  background-color: #000000;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 2em;
  h2,
  p {
    max-width: 360px;
  }
  p {
    font-size: 26px;
  }
  @media (min-width: 400px) {
    width: 550px;
    height: 550px;
    h2,
    p {
      max-width: 500px;
    }
  }
`;

const InfoBubbles = () => {
  return (
    <Div>
      <StyledBlock className="black-circle margin-bottom">
        <h2>Looking for Press Information?</h2>
        <ButtonGrey
          textLabel="Go To Press Room"
          pageLink="/about/press-room"
          className="whiteCTA"
        />
      </StyledBlock>
      <StyledBlock className="red-circle margin-bottom">
        <h2>Our Fearless Leaders</h2>
        <p>
          Get to know our executives and board of directors.
        </p>
        <ButtonGrey textLabel="MEET THE LEADERSHIP" pageLink="about/leadership" />
      </StyledBlock>

      <StyledBlock className="gray-circle">
        <h2>Join Our Team</h2>
        <p>Calling all experience enthusiasts.</p>
        <ButtonGrey
          textLabel="Search Jobs"
          externalLink="https://jobs.lever.co/ansira"
          className="whiteCTA"
        />
      </StyledBlock>

      <StyledBlock className="black-circle last">
        <h2>Where to Find Us</h2>
        <p>We have offices from St. Louis to London.</p>
        <ButtonGrey
          textLabel="See Locations"
          pageLink="/contact"
          className="whiteCTA"
        />
      </StyledBlock>
    </Div>
  );
};

export default InfoBubbles;
