import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

//IMAGES
import MobileVideo from '../images/video-horizontal.png';

//COMPONENTS
import InfoBubbles from '../components/InfoBubbles';
import ModalVideo from 'react-modal-video';
import Hero from '../components/Hero';
import ContactBanner from '../components/ContactBanner';
import NewsSection from '../components/News/NewsSection';
import SEO from '../components/SEO';
import ImageCircle from '../components/ImageCircle';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';

const StyledAbout = styled.main`
  margin: 0 auto;
  max-width: 1500px;
  overflow-x: hidden;
  @media (min-width: 1500px) {
    overflow-x: visible;
  }
  .max-width {
    max-width: 1500px;
    margin: 0 auto;
  }
  .intro-copy {
    width: 100%;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .col-md-7 {
    padding-right: 0;
    padding-left: 0;
  }

  .award {
    max-width: 220px;
    margin: 0 auto;
    text-align: center;
    a {
      color: rgba(0, 0, 0, 0.8);
    }
    .img-cont {
      max-width: 200px;
      margin: 0 auto;
      padding: 0 10px;
      img {
        min-width: 140px;
        width: 100%;
      }
    }
  }
  .center {
    margin-top: 5rem;
    text-align: center;
  }
  h2.center {
    font-size: 48px;
  }
  .img-section.right {
    img.desktop {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .hero-container {
      width: 60%;
    }
    .intro-copy {
      width: 100%;
    }
    .img-section.right {
      img.mobile {
        display: none;
      }
      img.desktop {
        display: block;
        margin-top: -220px;
        margin-left: auto;
      }
    }

    .right {
      width: 45%;
      padding-top: 0;
    }
    .awards-container {
      display: flex;
      flex-wrap: wrap;
    }
    h2.center {
      margin-bottom: 2em;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .hero-container {
      width: 100%;
    }
    .bullet-section {
      padding: 8em 0;
    }
  }

  .sub-section {
    p {
      font-weight: 400;
      color: ${colors.primaryGray};
    }
    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2rem;
      h2 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 0.8rem;
        @media (min-width: ${breakpoints.tablet}) {
          font-size: 28px;
        }
      }
    }
    .img-section {
      padding: 0px 0 40px;
    }
    .left {
      order: 1;
    }
    .right {
      order: 2;
    }
    .body-copy {
      font-size: 22px;
      width: 100%;
    }

    @media (max-width: ${breakpoints.mobileMax}) {
      .img-section.left {
        width: 120vw;
        margin: 0 calc(-150px + 20vw);
      }
    }

    @media (min-width: ${breakpoints.tablet}) {
      /* position: relative; */
      .img-section {
        padding: 0;
        &.left {
          right: 190px;
        }
      }
    }

    @media (min-width: ${breakpoints.max}) {
      .img-section {
        &.left {
          right: 15%;
        }
      }
    }
  }

  @keyframes modal-video {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }

  .modal-video-effect-exit {
    opacity: 0;

    & .modal-video-movie-wrap {
      -webkit-transform: translate(0, 100px);
      -moz-transform: translate(0, 100px);
      -ms-transform: translate(0, 100px);
      -o-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
  }

  .modal-video-body {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }

  .modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -ms-transition: -ms-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: 0;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
    @media (min-width: 1000px) {
      right: -35px;
    }
    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 5px;
      margin-top: -6px;
    }
  }
`;

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPreviewMode: false,
      previewPayload: null,
      isOpen: false
    };
    // bind functions
    this.openModal = this.openModal.bind(this);
  }

  // open modal (set isOpen, false)
  openModal() {
    this.setState({
      isOpen: true
    });
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------- -----------------
    */
  }

  render() {
    let about_page = this.props.data.wordpressPage;

    if (this.state.isPreviewMode) {
      about_page.acf = this.state.previewPayload.acf;
    }

    const LeaderBoardData = this.props.data.allWordpressWpOurBoard.edges.concat(
      this.props.data.allWordpressWpOurLeadership.edges
    );
    return (
      <StyledAbout className="animate">
        <SEO
          isBlogPost={false}
          postData={about_page}
          postImage={DefaultSharingImage}
        />
        <Hero
          headline={about_page.acf.hero_headline}
          sub={about_page.acf.hero_sub_headline}
        />
        <div className="page-container max-width">
          <div className="row sub-section">
            <div className="col-md-7 content copy left">
              <div className="intro-copy">
                {ReactHtmlParser(about_page.acf.intro_copy)}
              </div>
              <h2>{about_page.acf.content_blocks[0].heading}</h2>
              <div className="body-copy">
                {ReactHtmlParser(about_page.acf.content_blocks[0].content)}
              </div>
            </div>
            <div className="col-md-5 img-section right">
              <ModalVideo
                channel="vimeo"
                isOpen={this.state.isOpen}
                videoId="388594508"
                onClose={() => this.setState({ isOpen: false })}
              />
              <a
                className={this.props.formatType + ' clickable'}
                onClick={this.openModal.bind(this)}
              >
                <img className="mobile" src={MobileVideo} />
                <img
                  className="desktop"
                  src={about_page.acf.content_blocks[0].image.source_url}
                />
              </a>
            </div>
          </div>
          <div className="row sub-section">
            <div className="col-md-7 content copy right">
              <h2>{about_page.acf.content_blocks[1].heading}</h2>
              <div className="body-copy">
                {ReactHtmlParser(about_page.acf.content_blocks[1].content)}
              </div>
            </div>
            <div className="col-md-5 img-section left">
              <ImageCircle
                img={about_page.acf.content_blocks[1].image.source_url}
              />
            </div>
          </div>

          <h2 className="center">Kudos to Us</h2>
          <div className="awards-container">
            {about_page.acf.awards.map(item => {
              return item.award_link ? (
                <div className="award">
                  <a className="award-link" href={item.award_link}>
                    <div className="img-cont">
                      <img src={item.award_image.source_url} />
                    </div>
                    <h4>{item.award_headline}</h4>
                  </a>
                  {ReactHtmlParser(item.award_copy)}
                </div>
              ) : (
                <div className="award">
                  <div className="img-cont">
                    <img src={item.award_image.source_url} />
                  </div>
                  <h4>{item.award_headline}</h4>
                  {ReactHtmlParser(item.award_copy)}
                </div>
              );
            })}
          </div>
        </div>
        <NewsSection />
        <InfoBubbles />
        <div className="about-container">
          <ContactBanner
            headline={about_page.acf.contact_headline}
            cta={about_page.acf.contact_cta}
            url={about_page.acf.contact_url}
          />
        </div>
      </StyledAbout>
    );
  }
}

export const query = graphql`
  query {
    allWordpressWpOurBoard {
      edges {
        node {
          wordpress_id
          better_featured_image {
            source_url
          }
        }
      }
    }
    allWordpressWpOurLeadership {
      edges {
        node {
          wordpress_id
          better_featured_image {
            source_url
          }
        }
      }
    }
    wordpressPage(slug: { eq: "about" }) {
      content
      slug
      title
      type
      content
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        intro_copy
        content_blocks {
          content
          heading
          image {
            source_url
          }
        }
        awards {
          award_image {
            source_url
          }
          award_headline
          award_copy
          award_link
        }
        contact_headline
        contact_cta
        contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default About;
